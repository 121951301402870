<!--
@description: footer
@author: fc
@createtime: 2021年11月21日 19:33:21
-->
<template>
  <div class="footer-container">
    <ul class="footer-content">
      <li @click="handleClick(1)">About</li>
      <li @click="handleClick(2)">FAQ</li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    handleClick(type) {
      if (type == 1) {
        window.open("https://github.com/CAAINS/coop");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";
.footer-container {
  background-color: #f6f6f6;
  color: $color-text;
  width: 100%;
  height: 60px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  .footer-content {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 0;
    justify-content: center;
    font-size: 1.4rem;
    color: $color-orange;
    li {
      cursor: pointer;
      padding: 0;
      margin: 0;
      list-style: none;
    }
    :nth-child(n + 1) {
      margin-left: 20px;
    }
  }
}
</style>
